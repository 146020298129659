import React from "react";
import {Col, Nav, Row} from "reactstrap";
import {Navbar} from "reactstrap";
import {NavbarToggler} from "reactstrap";
import {Collapse} from "reactstrap"
import {NavbarBrand} from "reactstrap";
import {NavLink} from "reactstrap";
import {NavItem} from "reactstrap";
import {Button} from "reactstrap";
import logo from "./logo.png";
import footerlogo from "./images/footer-logo.png";
import goat1 from "./images/8_bit_goat_1_web.jpg";
import goat2 from "./images/8_bit_goat_2_web.jpg";
import goat3 from "./images/8_bit_goat_3_web.jpg";
import goat4 from "./images/8_bit_goat_4_web.jpg";
import goat5 from "./images/8_bit_goat_5_web.jpg";
import goat6 from "./images/8_bit_goat_6_web.jpg";
import goat7 from "./images/8_bit_goat_7_web.jpg";
import goat8 from "./images/8_bit_goat_8_web.jpg";
import goat9 from "./images/8_bit_goat_9_web.jpg";
import goat10 from "./images/8_bit_goat_10_web.jpg";
import { SocialIcon } from 'react-social-icons';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';

function App() {
    const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="App">

        <div className={"bg"}>

            {/* This is the Navigation block */}
            <div>
                <Navbar expand="xl" color="light" fixed="top" light>
                    <NavbarBrand href="/">
                        <img src={logo} alt={"8BITGOATS.com"} width={"250px"} className="d-inline-block align-middle mr-2" />
                    </NavbarBrand>
                    <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto" navbar>
                            <NavItem><NavLink href={"https://medium.com/@bjanash/8bitgoats-cb7e92c623b2"} className={"nav-link"} target={"_blank"}>About</NavLink></NavItem>
                            <NavItem><NavLink href={"https://opensea.io/collection/8bitgoats"} className={"nav-link"} target={"_blank"}>The Collection</NavLink></NavItem>
                            <NavItem><NavLink href={"https://twitter.com/8bitgoats"} className={"nav-link"} target={"_blank"}>Twitter</NavLink></NavItem>
                            <NavItem><NavLink href={"https://www.reddit.com/user/8bitgoatsnft"} className={"nav-link"} target={"_blank"}>Reddit</NavLink></NavItem>
                            <NavItem><NavLink href={"https://instagram.com/8bitgoats"} className={"nav-link"} target={"_blank"}>Instagram</NavLink></NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            {/* This is a container for the hero info. */}
            <div className={"heroInfo"}>
                {/* This is the H1 Test */}
                <h1> ONE HUNDRED AND ONE<br/>
                    8-BIT INSPIRED<br/>
                    ONE-OF-A-KIND GOATS</h1>
                <h2>NFTs drop every month<br/> for the next 10 months</h2>
                {/* This is the button block */}
                <div>
                   <a target={"_blank"} href={"https://opensea.io/collection/8bitgoats"}><Button color="primary" size="lg" className={"btn"}>
                        View All the GOATs on OpenSea
                   </Button></a>
                </div>

            </div>

        {/* 8BITGOATS */}
        <div className={"goats container-fluid"}>
            <section>
                <Row>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538341683822329857/"} target={"_blank"}> <img className={"goat"} src={goat1} alt={"8BitGoats | GOAT 1"}/>
                            <div className="middle">
                                <div className="goatText">View GOAT #1 on OpenSea</div>
                            </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538342783333957633/"} target={"_blank"}> <img className={"goat"} src={goat2} alt={"8BitGoats | GOAT 2"}/> <div className="middle">
                            <div className="goatText">View GOAT #2 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538344982357213185/"} target={"_blank"}> <img className={"goat"} src={goat3} alt={"8BitGoats | GOAT 3"}/> <div className="middle">
                            <div className="goatText">View GOAT #3 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538347181380468737/"} target={"_blank"}> <img className={"goat"} src={goat4} alt={"8BitGoats | GOAT 4"}/> <div className="middle">
                            <div className="goatText">View GOAT #4 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                        </Col>
                </Row>
                <Row>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538348280892096513/"} target={"_blank"}> <img className={"goat"} src={goat5} alt={"8BitGoats | GOAT 5"}/> <div className="middle">
                            <div className="goatText">View GOAT #5 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538349380403724289/"} target={"_blank"}> <img className={"goat"} src={goat6} alt={"8BitGoats | GOAT 6"}/> <div className="middle">
                            <div className="goatText">View GOAT #6 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538350479915352065/"} target={"_blank"}><img className={"goat"} src={goat7} alt={"8BitGoats | GOAT 7"}/> <div className="middle">
                            <div className="goatText">View GOAT #7 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538351579426979841/"} target={"_blank"}><img className={"goat"} src={goat8} alt={"8BitGoats | GOAT 8"}/> <div className="middle">
                            <div className="goatText">View GOAT #8 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538353778450235393/"} target={"_blank"}> <img className={"goat"} src={goat9} alt={"8BitGoats | GOAT 9"}/> <div className="middle">
                            <div className="goatText">View GOAT #9 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                    <Col sm="auto" md={"3"}>
                        <div className="goatContainer">
                        <a href={"https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/103572147695535379316544832186897508949410532058995376605591538355977473490945/"} target={"_blank"}><img className={"goat"} src={goat10} alt={"8BitGoats | GOAT 10"}/> <div className="middle">
                            <div className="goatText">View GOAT #10 on OpenSea</div>
                        </div>
                        </a>
                        </div>
                    </Col>
                </Row>
            </section>
        </div>


        {/* Footer */}

        <footer className="footer">

            <section className="d-flex justify-content-lg-between p-4">

                <div className="me-5 d-lg-block">
                    <a href={"#"}><img src={footerlogo} className={"footerlogo"} alt={"8BITGOATS.com"}/></a>
                    <p className={"footerMenu"}><a target={"_blank"} href={"https://medium.com/@bjanash/8bitgoats-cb7e92c623b2"} className={"footerLink"}>About</a></p>
                    <p className={"footerMenu"}><a target={"_blank"} href={"https://opensea.io/collection/8bitgoats"} className={"footerLink"}>The Collection</a></p>
                    <p className={"footerMenu"}><a target={"_blank"} href={"https://twitter.com/8bitgoats"} className={"footerLink"}>Twitter</a></p>
                    <p className={"footerMenu"}><a target={"_blank"} href={"https://www.reddit.com/user/8bitgoatsnft"} className={"footerLink"}>Reddit</a></p>
                    <p className={"footerMenu"}><a target={"_blank"} href={"https://instagram.com/8bitgoats"} className={"footerLink"}>Instagram</a></p>
                </div>

                <div className={"d-none d-lg-block"}>
                    <a href="" className="me-4 text-reset">
                        <SocialIcon url="https://twitter.com/8bitgoats" target={"_blank"} />
                    </a>
                    <a href="" className="me-4 text-reset">
                        <SocialIcon url="https://www.reddit.com/user/8bitgoatsnft" target={"_blank"} />
                    </a>
                    <a href="" className="me-4 text-reset">
                        <SocialIcon url="https://instagram.com/8bitgoats" target={"_blank"} />
                    </a>
                    <a href="" className="me-4 text-reset">
                        <SocialIcon url="https://medium.com/@bjanash/8bitgoats-cb7e92c623b2" target={"_blank"} />
                    </a>
                </div>

            </section>



<section>
            <div className={"copyright"}>
                ©2022 Indysoft LLC | <a target={"_blank"} href={"https://app.termly.io/document/terms-of-use-for-website/f8b7fd95-d1c5-4cca-9d10-f9cffb9503f3"} className={"footerLink"}>Terms</a> | <a target={"_blank"} href={"https://app.termly.io/document/privacy-policy/acaab705-d79c-432d-8e68-d04287305ca0"} className={"footerLink"}>Privacy</a>
            </div>
</section>


        </footer>
        </div>
    </div>
  );
}

export default App;

